export default {
  title: {
    en: 'Build your scent profile with our quiz',
  },
  text: {
    en: `Tell us about the scents you gravitate towards, and we'll find fragrances tailored to fit your tastes.`,
  },
  buttonTitle: {
    en: 'Get started with a quiz',
  },
  seeResults: {
    en: 'See your results',
  },
  retakeQuiz: {
    en: 'Retake the quiz',
  },
}
