import React from 'react'
import { useDevice } from 'device'

import { twcx } from 'helpers'
import { Label, RichText, Text, type TextStyle, type BaseLabelProps, type TextColor } from 'components/dataDisplay'
import { Button, buttonMessages, type ButtonProps } from 'components/inputs'


export type BaseContentProps = {
  supTitle?: string | Intl.Message
  title?: string | Intl.Message
  titleStyle?: TextStyle
  text?: string | Intl.Message
  richText?: string | Strapi.RichTextState
  secondaryRichText?: string | Strapi.RichTextState
  labelTitle?: string | Intl.Message
  labelProps?: Pick<BaseLabelProps, 'bgColor' | 'textColor' | 'className'>
  buttonProps?: {
    title?: string | Intl.Message
    inlineIcon?: ButtonProps['inlineIcon']
    'data-testid'?: string
  } & AtLeastOne<{
    to: string
    onClick: React.MouseEventHandler
  }>
}

const BaseContent: React.FunctionComponent<BaseContentProps> = (props) => {
  const { supTitle, title, titleStyle, text, buttonProps, richText, labelTitle, labelProps, secondaryRichText } = props

  const { isMobile } = useDevice()

  const baseTitleStyle = isMobile ? 'h4' : 'h3'

  return (
    <>
      {
        Boolean(labelTitle) && (
          <Label
            className={twcx('mb-16', labelProps?.className)}
            title={labelTitle}
            style="eye3"
            textColor={labelProps?.textColor || 'white'}
            bgColor={labelProps?.bgColor || 'black'}
          />
        )
      }
      {
        Boolean(supTitle) && (
          <Text
            className="mb-16"
            message={supTitle}
            style="eye1"
            color="gold-30"
          />
        )
      }
      {
        Boolean(title) && (
          <Text
            message={title}
            style={titleStyle || baseTitleStyle}
            color="white"
            html
          />
        )
      }
      {
        Boolean(text) && (
          <Text
            className="mt-16"
            message={text}
            style="p1"
            color="white"
          />
        )
      }
      {
        Boolean(richText) && (
          <RichText
            className={text || title ? 'mt-16' : null}
            message={richText}
          />
        )
      }
      {
        Boolean(secondaryRichText) && (
          <RichText
            className="mt-16"
            message={secondaryRichText}
          />
        )
      }
      {
        Boolean(buttonProps) && (
          <Button
            className={isMobile ? 'mt-16' : 'mt-24'}
            title={buttonProps.title || buttonMessages.details}
            inlineIcon={buttonProps.inlineIcon}
            to={buttonProps.to}
            style="gold-30"
            size={56}
            width={320}
            fullWidthOnMobile
            onClick={buttonProps.onClick}
            data-testid={buttonProps['data-testid']}
          />
        )
      }
    </>
  )
}


export default React.memo(BaseContent)
