import React from 'react'
import { useDevice } from 'device'
import { useUser } from 'modules/user'

import { HeroMediaBlock } from 'components/dataDisplay'

import QuizEditorialBanner from 'compositions/banners/QuizEditorialBanner/QuizEditorialBanner'


type QuizSectionProps = {
  className?: string
}

const QuizSection: React.FC<QuizSectionProps> = ({ className }) => {
  const { isMobile } = useDevice()

  const { user } = useUser()
  const hasScentProfile = Boolean(user?.analyticsMetadata?.hasScentProfile)

  if (isMobile) {
    return (
      <div>
        <QuizEditorialBanner
          className={className}
          withScentProfile={hasScentProfile}
          withButtonTitleArrow={!hasScentProfile}
        />
      </div>
    )
  }

  return (
    <div>
      <HeroMediaBlock className={className} wideMT={false} wideMB={false}>
        <QuizEditorialBanner
          withScentProfile={hasScentProfile}
        />
      </HeroMediaBlock>
    </div>
  )
}


export default QuizSection
