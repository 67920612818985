import React from 'react'
import cx from 'classnames'
import links from 'links'
import { track } from 'analytics'

import { Button } from 'components/inputs'
import EditorialBlock from 'compositions/blocks/EditorialBlock/EditorialBlock'

import messages from './messages'

import mobileImage from './images/bg-mobile.jpg'
import desktopImage from './images/bg-desktop.jpg'


const bgImage = {
  mobile: mobileImage,
  desktop: desktopImage,
}

type QuizEditorialBannerProps = {
  className?: string
  lazy?: boolean
  withScentProfile?: boolean
  withButtonTitleArrow?: boolean
}

const QuizEditorialBanner: React.FunctionComponent<QuizEditorialBannerProps> = (props) => {
  const { className, lazy = true, withScentProfile, withButtonTitleArrow } = props

  const primaryLink = withScentProfile ? links.scentProfile : links.smartRecommendations

  return (
    <EditorialBlock
      className={cx(className, 'bg-light-beige')}
      bgImage={bgImage}
      imageLazy={lazy}
      imageFirst
    >
      <EditorialBlock.BaseContent
        title={messages.title}
        text={messages.text}
        buttonProps={{
          title: withScentProfile ? messages.seeResults : messages.buttonTitle,
          inlineIcon: withButtonTitleArrow ? '32/arrow-right' : null,
          to: primaryLink,
          onClick: () => {
            track('Banner click', {
              placement: 'Build your scent profile',
              content: 'Quiz',
              link: primaryLink,
              action: 'link',
            })
          },
          'data-testid': withScentProfile ? 'seeResults' : 'takeQuiz',
        }}
      />
      {
        withScentProfile && (
          <Button
            className="mt-16"
            title={messages.retakeQuiz}
            to={links.smartRecommendations}
            style="gold-30-secondary"
            size={56}
            width={320}
            fullWidthOnMobile
            onClick={() => track('Banner click', {
              placement: 'Build your scent profile',
              content: 'Quiz',
              link: links.smartRecommendations,
              action: 'link',
            })}
            data-testid="takeQuiz"
          />
        )
      }
    </EditorialBlock>
  )
}


export default React.memo(QuizEditorialBanner)
